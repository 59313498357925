import React, {useState, useEffect, useContext} from 'react';
import Header from '../Header/index.jsx';
import { Button, Form, Row, Col } from 'react-bootstrap';
import apiService from '../../../../services/api.js';
import apiLocations from '../../../../services/apiLocations.js';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Loading from '../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';
import Context from "../../../../Context";
import InputMask from "react-input-mask";

const RegisterEdit = ({ match, location }) => {
	const [configDefault] = useContext(Context);
	// eslint-disable-next-line
	const [endpoint, setEndpoint] = useState('store');
	// eslint-disable-next-line
    const [roleEndpoint, setRoleEndpoint] = useState('store');

	const {
	    params: { id }
	} = match;

	const [users, setUsers] = useState([]);
	const [states, setStates] = useState([]);
	const [cities, setCities] = useState([]);
	const api = apiService.get();
	const history = useHistory();
	const [errors, setErrors] = useState('');
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, setValue, getValues } = useForm();
	
	const onSubmit = data => {
		data.uf = states.find(function(el){
		    return el.id == data.state_id;/* eslint-disable-line */
		})?.sigla;

		data.state = states.find(function(el){
		    return el.id == data.state_id;/* eslint-disable-line */
		})?.nome;

		data.city = cities.find(function(el){
		    return el.id == data.city_id;/* eslint-disable-line */
		})?.nome;

		data.zipcode = parseInt(data.zipcode);

		let formData = new FormData();

		formData.append('name', data.name);
		formData.append('cnpj', data.cnpj);
		formData.append('ie', data.ie);
		formData.append('corporate_name', data.corporate_name);
		formData.append('user_id', data.user_id);
		formData.append('zipcode', data.zipcode);
		formData.append('uf', data.uf);
		formData.append('state_id', data.state_id);
		formData.append('city_id', data.city_id);
		formData.append('street', data.street);
		formData.append('neighborhood', data.neighborhood);
		formData.append('number', data.number);
		formData.append('complement', data.complement);
		
		if(data?.certificate[0] !== undefined) {
			formData.append('certificate', data.certificate[0]);
		}

		if(id !== undefined)
			update(data);
		else
			create(data);
	}

	const create = data => {
		setLoading(true);
		
		api.post(`api/${endpoint}`, data)
		.then(resp => {
			showNotify('success', 'Loja registrada com sucesso!');
			setLoading(false);
			history.push(`/dashboard/${endpoint}/list`);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.errors)
			setLoading(false);
			verifyError(error);
		})
	}

	const update = data => {
		setLoading(true);

		api.put(`api/${endpoint}/${id}`, data)
		.then(resp => {
			showNotify('success', 'Loja atualizada com sucesso!');
			setLoading(false);
			history.push(`/dashboard/${endpoint}/list`);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.errors);
			setLoading(false);
			verifyError(error);
		})
	}

	const onChangeState = (ev) => {
		const { value } = ev.target;
		
		apiLocations.get(`estados/${value}/municipios`)
		.then((res) => {
			setCities(res.data);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			console.log(error);
		})
	}

	const initUsers = () => {
		api.get('api/user/all')
		.then(resp => {
			setUsers(resp.data.data.users);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}

	const initStates = () => {
	    apiLocations.get('estados')
		.then((res) => {
			setStates(res.data);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			console.log(error);
		})
	}

	const initCities = (idState) => {
		apiLocations.get(`estados/${idState}/municipios`)
		.then((res) => {
			setCities(res.data)
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			console.log(error);
		})
	}

	const initEndpoint = () => {
		setLoading(true);
		api.get(`api/${endpoint}/${id}`)
		.then(resp => {
			const data = resp.data.data.store;
			setValue("name", data?.name);
			setValue("cnpj", data?.cnpj);
			setValue("ie", data?.ie);
			setValue("corporate_name", data?.corporate_name);
			setValue("user_id", data?.user_id);
			setValue("zipcode", `${data?.address?.zipcode}`);
			setValue("state_id", data?.address?.state_id);
			setValue("city_id", data?.address?.city_id);
			setValue("street", data?.address?.street);
			setValue("neighborhood", data?.address?.neighborhood);
			setValue("number", data?.address?.number);
			setValue("complement", data?.address?.complement);
			
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}


	useEffect(() => {
		initUsers()
		initStates()
		if(id !== undefined){
			initEndpoint();
			initCities(getValues("state_id"));
		}else{
			document.getElementById('form-register').reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, getValues("state_id")])

	return (
		<>
			<div className="area-crud">
				<Header></Header>

				<Can 
					resource={roleEndpoint}
					authority={`${roleEndpoint}-create`}
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Formulário de registro</h5>

					{ (loading) ? (<Loading />) : (null)}

					<Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="form-register" id="form-register">
						<Row className="mb-3">
							<Col sm="4">
								<Form.Group>
								    <label htmlFor="name" className="form-label">Nome:<span className="req-span">*</span></label>
								    <input type="text" name="name" className="form-control" id="name" {...register("name")} />
								    <span className="error-message">{errors?.name ? errors.name[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label htmlFor="corporate_name" className="form-label">Nome Fantasia:<span className="req-span">*</span></label>
								    <input type="text" name="corporate_name" className="form-control" id="corporate_name" {...register("corporate_name")} />
								    <span className="error-message">{errors?.corporate_name ? errors.corporate_name[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label htmlFor="ie" className="form-label">Inscrição estadual:<span className="req-span">*</span></label>
								    <input type="text" name="ie" className="form-control" id="ie" {...register("ie")} />
								    <span className="error-message">{errors?.ie ? errors.ie[0] : null}</span>
								</Form.Group>
							</Col>

						</Row>
						<Row className="mb-3">
							<Col sm="4">
								<Form.Group>
								    <label htmlFor="cnpj" className="form-label">CNPJ:</label>
								    <InputMask mask="99.999.999/9999-99" name="cnpj" className="form-control" id="cnpj" {...register("cnpj")} />
								    <span className="error-message">{errors?.cnpj ? errors.cnpj[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label htmlFor="user_id" className="form-label">Usuário:</label>
								    <select className="form-select" name="user_id" {...register("user_id")}>
								    	<option value="">Selecionar</option>
							            {users?.map((user, index) => (
								    		<option value={user.id} key={index}>{user.name}</option>
								    	))}
							        </select>
								    <span className="error-message">{errors?.user_id ? errors.user_id[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label htmlFor="certificate" className="form-label">Certificado:</label>
								    <input type="file" name="certificate" className="form-control" id="certificate" {...register("certificate")} />
								    <span className="error-message">{errors?.certificate ? errors.certificate[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>


						<Row className="mb-3">
							<Col sm="3">
								<Form.Group>
								    <label htmlFor="zipcode" className="form-label">CEP:</label>
								    <InputMask mask="99999999" name="zipcode" className="form-control" id="zipcode" {...register("zipcode")} />
								    <span className="error-message">{errors?.zipcode ? errors.zipcode[0] : null}</span>
								</Form.Group>
							</Col>
							<Col sm="4">
								<Form.Group>
								    <label htmlFor="state" className="form-label">Estado:</label>
								    <select className="form-select" name="state_id" {...register("state_id")} onChange={(ev) => onChangeState(ev)} >
								    	<option value="">Selecionar</option>
								    	{states?.map((state, index) => (
								    		<option value={state.id} key={index}>{state.sigla}</option>
								    	))}
							        </select>
								    <span className="error-message">{errors?.state_id ? errors.state_id[0] : null}</span>
								</Form.Group>
							</Col>
							<Col sm="5">
								<Form.Group>
								    <label htmlFor="city" className="form-label">Cidade:</label>
								    <select className="form-select" name="city_id" {...register("city_id")} >
								    	<option value="">Selecionar</option>
							            {cities?.map((city, index) => (
								    		<option value={city.id} key={index}>{city.nome}</option>
								    	))}
							        </select>
								    <span className="error-message">{errors?.city_id ? errors.city_id[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="5">
								<Form.Group controlId="formBasicEmail">
								    <label htmlFor="street" className="form-label">Endereço:</label>
								    <input type="text" name="street" className="form-control" id="street" {...register("street")} />
								    <span className="error-message">{errors?.street ? errors.street[0] : null}</span>
								</Form.Group>
							</Col>
							<Col sm="5">
								<Form.Group controlId="formBasicEmail">
								    <label htmlFor="neighborhood" className="form-label">Bairro:</label>
								    <input type="text" name="neighborhood" className="form-control" id="neighborhood" {...register("neighborhood")} />
								    <span className="error-message">{errors?.neighborhood ? errors.neighborhood[0] : null}</span>
								</Form.Group>
							</Col>
							<Col sm="2">
								<Form.Group controlId="formBasicEmail">
								    <label htmlFor="number" className="form-label">Nº:</label>
								    <input type="text" name="number" maxLength="6" className="form-control" id="number" {...register("number")} />
								    <span className="error-message">{errors?.number ? errors.number[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="12">
								<Form.Group>
								    <label htmlFor="complement" className="form-label">Complemento:</label>
								    <input type="text" name="complement" className="form-control" id="complement" {...register("complement")} />
								    <span className="error-message">{errors?.complement ? errors.complement[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

					    <Row className="mb-3">
					    	<Col>
					    		<Button type="submit" className="btn" style={configDefault?.theme?.prymaryBackground}>{id !== undefined ? 'Salvar' : 'Cadastrar'}</Button>
					    	</Col>
					    </Row>
					    
					</Form>
				</Can>
			</div>
		</>
	);
};

export default RegisterEdit;