import React, {useState, useEffect, useContext} from 'react';
import { SketchPicker } from 'react-color';
import { Button, Form, Row, Col } from 'react-bootstrap';
import apiService from '../../../../services/api.js';
import Loading from '../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import EndpointsTypesService from '../../../../components/EndpointsTypesService/index.jsx';
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';
import Maps from '../../../../components/Map/index.jsx';
import Context from "../../../../Context";
import ConfigDataCompany from "./ConfigDataCompany/index.jsx";
import './style.css';



const AdminConfigOverview = () => {
	const [configDefault, setConfigDefault] = useContext(Context);

	const [loading, setLoading] = useState(false);
	const api = apiService.get();
	// eslint-disable-next-line
	const [errors, setErrors] = useState('');
	
	const [configSystem, setConfigSystem] = useState({});

	const [zoomLocale, setZoomLocale] = useState(3);
	const [latLocale, setLatLocale] = useState(0);
	const [longLocale, setLongLocale] = useState(0);
	const [fontColorSidebar, setFontColorSidebar] = useState('');
	const [bgColorSidebar, setBgColorSidebar] = useState('');
	const [fontColorPromary, setFontColorPromary] = useState('');
	const [bgColorPromary, setBgColorPromary] = useState('');
	const [colorIsChanged, setColorIsChanged] = useState(false);
	const [showGatewayPaymentForm, setShowGatewayPaymentForm] = useState(false);
	const [paymentIntegrationOptions, setPaymentIntegrationOptions] = useState([]);
	const [paymentIntegrationOptionSelected, setPaymentIntegrationOptionSelected] = useState('');
	const [paymentIntegrationData, setPaymentIntegrationData] = useState('');

	useEffect(() => {
		if(colorIsChanged){
			let configDefaultTemp = {
		        theme:{
			        sidebarFontColor: {
			            color: fontColorSidebar,
			        },
			        sidebarBgColor: {
			            backgroundColor: bgColorSidebar,
			        },
			        prymaryColor: {
			            color: fontColorPromary,
			        },
			        prymaryBackground: {
			            backgroundColor: bgColorPromary,
			            border: 'none',
			        },
			    },
			    mapDefault: {
		        	zoom: zoomLocale,
		        	lat: latLocale,
		        	longi: longLocale,
			    }
		    };
	    	setConfigDefault(configDefaultTemp);
	    	setColorIsChanged(false);
	    }

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [colorIsChanged])
	

	const handleChangeFontColorSidebar = (color) => {
		setFontColorSidebar(color.hex);
		setColorIsChanged(true);
	}
	const handleChangeBgColorSidebar = (color) => {
		setBgColorSidebar(color.hex);
		setColorIsChanged(true);
	}
	const handleChangeFontColorPrimary = (color) => {
		setFontColorPromary(color.hex);
		setColorIsChanged(true);
	}
	const handleChangeBgColorPrimary = (color) => {
		setBgColorPromary(color.hex);
		setColorIsChanged(true);
	}	
	
	
	const initConfigSystem = async () => {
		setLoading(true);

		api.get(`api/config`)
		.then(resp => {
			let configTemp = resp.data.data.config; 
			setConfigSystem(configTemp);
			setZoomLocale(configTemp.zoom_default);
			setLatLocale(configTemp.lat_default);
			setLongLocale(configTemp.longi_default);
			setFontColorSidebar(configTemp.font_color_sidebar);
			setBgColorSidebar(configTemp.bg_sidebar);
			setFontColorPromary(configTemp.font_primary_color);
			setBgColorPromary(configTemp.bg_primary_color);
			setPaymentIntegrationData(configTemp.payment_integration_data);
			setPaymentIntegrationOptionSelected(configTemp.payment_integration_name);
			getPaymentIntegrationOptions();
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoading(false);
			verifyError(error);
		})
		
	}
	
	const getPaymentIntegrationOptions = async () => {
		try {
			const ret = await api.get(`api/config/payment-integration-options`);
			setPaymentIntegrationOptions(ret.data.data?.options || []);
		} catch (error) {
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoading(false);
			verifyError(error);
		}
	}

	const submitLayout = (event) => {
		setLoading(true);

		let data = {
			lat_default: latLocale,
			longi_default: longLocale,
			zoom_default: zoomLocale,
			font_color_sidebar: fontColorSidebar,
			bg_sidebar: bgColorSidebar,
			font_primary_color: fontColorPromary,
			bg_primary_color: bgColorPromary,
			payment_integration_name: paymentIntegrationOptionSelected,
			payment_integration_data: paymentIntegrationData
		}

		api.put(`api/config/${configSystem?.id}`, data)
		.then(resp => {
			initConfigSystem();
			setLoading(false);
			document.location.reload(true);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoading(false);
		})
	}

	useEffect(() => {
		initConfigSystem();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const gatewayPaymentsToggle = async () => {
		setShowGatewayPaymentForm(state => !state);
	}

	return (
		<>
			<div className="area-crud">

				<h1 className="mb-5">Configurações do sistema</h1>

				<Can 
					resource="type-crud"
					authority="type-crud-view"
					otherwiseComponent={<label>Sem acesso!</label>}
				>

					{ (loading) ? (<Loading />) : (
						<>
							<EndpointsTypesService></EndpointsTypesService>

							<ConfigDataCompany></ConfigDataCompany>
							
							{/*Configuração do layout*/}
						    <h5 className="mb-4">Layout</h5>
		                	<div className="row mb-4">
		                		<Form onSubmit={submitLayout}>
		                			<Row className="mb-3">
		                				<Row className="mb-3">
											<Col sm="3">
												<Form.Group>
												    <label htmlFor="font_color_sidebar" className="form-label">Cor da fonte do sidebar:</label>
												    <SketchPicker color={ fontColorSidebar } onChangeComplete={ handleChangeFontColorSidebar } />
												</Form.Group>
											</Col>
											<Col sm="3">
												<Form.Group>
												    <label htmlFor="bg_sidebar" className="form-label">Plano de fundo do sidebar:</label>
												    <SketchPicker color={ bgColorSidebar } onChangeComplete={ handleChangeBgColorSidebar } />
												</Form.Group>
											</Col>
										
											<Col sm="3">
												<Form.Group>
												    <label htmlFor="font_primary_color" className="form-label">Cor da fonte primária:</label>
												    <SketchPicker color={ fontColorPromary } onChangeComplete={ handleChangeFontColorPrimary } />
												</Form.Group>
											</Col>

											<Col sm="3">
												<Form.Group>
												    <label htmlFor="bg_primary_color" className="form-label">Plano de fundo primária:</label>
												    <SketchPicker color={ bgColorPromary } onChangeComplete={ handleChangeBgColorPrimary } />
												</Form.Group>
											</Col>
										</Row>

										{/*Configuração do mapa*/}
				                		{/* <Row className="mb-3">
											<Col sm="4">
												<label className="form-label">Alterar visualização do mapa padrão</label><br/>
												<Maps 
													zoomLocale = {zoomLocale}
													setZoomLocale = {setZoomLocale}
													latLocale = {latLocale}
													setLatLocale = {setLatLocale}
													longLocale = {longLocale}
													setLongLocale = {setLongLocale}
												></Maps>
											</Col>
										</Row> */}

										{/* Configuração do metodo de pagamento */}
										<Row className="mb-3">
											<Col sm="12">
												<label className="form-label">Gateway de pagamentos</label><br/> 
												<Button onClick={gatewayPaymentsToggle} className='mb-2'>Configurar</Button>
											</Col>
											{showGatewayPaymentForm &&
												<Col>
													<select name="payment_integration_name" id="payment-optons" className='form-select mb-2' onChange={ev => setPaymentIntegrationOptionSelected(ev.target.value)}>
														<option value="">Selecione uma opção abaixo</option>
														{ 
														paymentIntegrationOptions?.map(option => {
															return <option selected={paymentIntegrationOptionSelected === option}>{option}</option>
														})
														}
													</select>
													<input type="text" name='payment_integration_data' placeholder='data (json)' className='form-control' onChange={ev => setPaymentIntegrationData(ev.target.value)} value={paymentIntegrationData}/>
													<small>ex.: {'{'}"token":"abcdefghijklmnopqrstuvxyz"{'}'}</small>
												</Col>
											}
										</Row>

										<Col sm="12">
											<Button type="submit" variant="primary" style={configDefault?.theme?.prymaryBackground}>Salvar</Button>
										</Col>
									</Row>
		                		</Form>
			                </div>
		                </>
		            )}
	            </Can>

			</div>
		</>
	);
};

export default AdminConfigOverview;