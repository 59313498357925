import React, {useState, useEffect} from 'react';
import { FaMoneyBillWave } from "react-icons/fa";
import { BiCreditCard, BiTransferAlt } from "react-icons/bi";
import { Modal, Button, Form, Col, Row  } from 'react-bootstrap';
//import Loading from '../../../../components/Loading/index.jsx';
import apiService from '../../../../services/api.js';
import {useSelector} from 'react-redux';
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';
import './style.css';
import { useHistory } from "react-router-dom";

const MethodsPayment = () => {
	const history = useHistory();
	const [methodsPayment, setMethodsPayment] = useState([]);
	const api = apiService.get();
	const [loading, setLoading] = useState(false);
	const [moneyReceived, setMoneyReceived] = useState(0);
	const [cashChange, setCashChange] = useState(0);
	const [resumePurchaseId, setResumePurchaseId] = useState(null);
	const [errors, setErrors] = useState('');
	const productsShoppingcart = useSelector(state => state.shoppingcart.products);
	const cpf = useSelector(state => state.shoppingcart.cpf);

	let idToten = JSON.parse(localStorage.getItem('toten')).id;
	let idStore = JSON.parse(localStorage.getItem('toten')).store_id;
	

	const [methodPaymentIdSelected, setMethodPaymentIdSelected] = useState(false);
	const [modalPaymentMoneyShow, setModalPaymentMoneyShow] = useState(false);
	const [awaitingPaymentModal, setAwaitingPaymentModal] = useState(false);
	const [purchaseStatus, setPurchaseStatus] = useState('');
	const [intervalId, setIntervalId] = useState(null);
	const onHideModalPaymentMoney = () => {setModalPaymentMoneyShow(false);}
	
	const onChangeMoneyReceived = (event) => {setMoneyReceived(parseInt(event.target.value));}

	const initMethodsPayment = () => {
		api.get(`api/toten/${idToten}`)
		.then(resp => {
			setMethodsPayment(resp.data.data.toten.methods_payment);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			//setErrors(error.message)
			verifyError(error);
		})
	}

	const selectMethodPayment = (methodPaymentId) => {
		setMethodPaymentIdSelected(methodPaymentId);
		setModalPaymentMoneyShow(true);
	}

	const createReceipt = () => {
		let data = {
			purchase_id: resumePurchaseId,
            products: []
        };
        data.products.push({
            code: '*',
            description: 'DESC',
            quantity_units: 'QTD UN',
            unitary_value: 'VL UNI',
            total: 'TOTAL',
        });

        productsShoppingcart.forEach(function(item, index){
            console.log(item);
            if(item.type == 'product'){
                data.products.push({
                    code: index,
                    description: item?.dataProduct?.product?.name,
                    quantity_units: item?.quantity,
                    unitary_value: item?.dataProduct?.price/100,
                    total: (item?.dataProduct?.price/100) * item?.quantity,
                });
            }

            if(item.type == 'wholesale'){
                data.products.push({
                    code: index,
                    description: item?.dataProduct?.name,
                    quantity_units: item?.quantity,
                    unitary_value: item?.dataProduct?.price/100,
                    total: (item?.dataProduct?.price/100) * item?.quantity,
                });
            }
        });

        api.post(`api/receipt/create-receipt`, data)
		.then(resp => {
			showNotify('success', 'Nota gerada com sucesso!');
			history.push('../panel-toten/finish-payment');
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.data?.errors)
			//setLoading(false);
			verifyError(error);
		})
	}

	const finishPurchase = async () => {
		//setLoading(true);
		let resumePurchaseIdTemp = resumePurchaseId;
		if(!resumePurchaseIdTemp) {
			resumePurchaseIdTemp = await resumePurchase();
		}

		let data = {
			method_payment_id: methodPaymentIdSelected,
			cpf: cpf,
			store_id: idStore,
			toten_id: idToten,
			money_received: moneyReceived*100,
			store_products: [],
		};

		productsShoppingcart.forEach(function(item){
			if(item?.type === 'product'){
				data.store_products.push({
					id: item?.dataProduct?.product?.id,
					quantity: item?.quantity,
					type: item?.type,
				});
			} else {
				data.store_products.push({
					id: item?.dataProduct?.product_id,
					quantity: item?.quantity,
					type: item?.type || 'product',
				});
			}
		});
		
		api.post(`api/purchase/finish/${resumePurchaseIdTemp}`, data)
		.then(resp => {
			if (resp.data.data.purchase.status === 'pending') {
				setPurchaseStatus(resp.data.data.purchase.status);
				showNotify('success', 'Aguardando...');
				setAwaitingPaymentModal(true);
				setModalPaymentMoneyShow(false);
			} else {
				showNotify('success', 'Compra realizada com sucesso!');
				createReceipt();
			}
			//setModalReceiptShow(true);
			//setLoading(false);
		})
		.catch(error => {
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.data?.errors)
			//setLoading(false);
			verifyError(error);
		})
	}

	

	const resumePurchase = async () => {
		try {
			setLoading(true);

			let data = {
				store_id: idStore,
				toten_id: idToten,
				money_received: moneyReceived * 100,
				store_products: [],
			};

			productsShoppingcart.forEach(function(item){
				if(item?.type === 'product'){
					data.store_products.push({
						id: item?.dataProduct?.product?.id,
						quantity: item?.quantity,
						type: item?.type,
					});
				}else {
					data.store_products.push({
						id: item?.dataProduct?.product_id,
						quantity: item?.quantity,
						type: item?.type || 'product',
					});
				}
			});

			let queryResumePurchase = resumePurchaseId == null ? `api/purchase/resume` : `api/purchase/resume/${resumePurchaseId}`;

			const resp = await api.post(queryResumePurchase, data);
			let respData = resp.data.data.resumePurchase;
			setCashChange(respData?.cash_change/100);
			await setResumePurchaseId(respData?.id);
			return respData?.id;

		} catch(error) {
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.data?.errors)
			verifyError(error);
		}
		setLoading(false);
	}

	const IconMethodPayment = ({nameIcon}) => {
		switch (nameIcon) {
			case 'icon-money':
				return <FaMoneyBillWave size="35" />;
		  	case 'icon-pix':
		  		return <BiCreditCard size="35" />;
			default:
		    	return <BiCreditCard size="35" />;
		}
	}

	useEffect(() => {
		initMethodsPayment();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const checkPaymentStatus = async () => {
			const resp = await api.get(`api/purchase/verify-payment/${resumePurchaseId}`);
			await setPurchaseStatus(resp.data.data.status);
		}

		if (awaitingPaymentModal && purchaseStatus !== 'paid') {
			let intervalId = setInterval(() => checkPaymentStatus(), 3000);
			setIntervalId(intervalId);
		}

		if (awaitingPaymentModal && intervalId && purchaseStatus === 'paid') {
			clearInterval(intervalId);
			showNotify('success', 'Pagamento realizado!');
			setAwaitingPaymentModal(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [awaitingPaymentModal, purchaseStatus])

	return (
		<>
			<div className="area-methods-payment">
				<p className="info-select-type-payment">Selecione o tipo de pagamento</p>

				<ul>
					{methodsPayment?.map((item, index) => (
						<li key={index} onClick={() => selectMethodPayment(item?.id)}>
							<IconMethodPayment nameIcon={item?.name_icon} />
							<label>{item?.name}</label>
						</li>
					))}
				</ul>
			</div>

			<Modal
                show={modalPaymentMoneyShow}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <h4 className="text-center mb-3">Finalizar pagamento</h4>
                    <Form className="form-register form-payment-money">
                    	{/* <Row className="mb-1">
							<Col sm="12">
								<Form.Group>
								    <label htmlFor="amount_paid" className="form-label">Valor pago em:</label>
								    <div class="input-group mb-3">
									    <span class="input-group-text" id="addon-wrapping">R$</span>
										<input type="number" min="0" step=".01" class="form-control" onChange={(e) => onChangeMoneyReceived(e)} placeholder="0.00" aria-label="0.00" />
										<button class="btn btn-outline-secondary" type="button" onClick={(e) => resumePurchase()}>Calcular</button>
									</div>
								    <label htmlFor="amount_paid" className="form-label">Troco em: {cashChange.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</label>
								    <span className="error-message">{errors?.amount_paid ? errors.amount_paid[0] : null}</span>
								</Form.Group>
							</Col>
						</Row> */}
						{/* {resumePurchaseId == null ? '' : */}
						    <Row>
						    	<Col sm="6">
						    		<Button type="button" className="btn-sm mb-3" onClick={(e) => finishPurchase()}>PAGAR</Button>
						    	</Col>
						    </Row>
					    {/* } */}
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button type="button" onClick={onHideModalPaymentMoney} className="btn-sm">Fechar</Button>
                </Modal.Footer>
            </Modal>

			<Modal
                show={awaitingPaymentModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <h4 className="text-center mb-3">Pagamento em espera</h4>
                    <Form className="form-register form-payment-money">
                    	{/* <Row className="mb-1">
							<Col sm="12">
								<Form.Group>
								    <label htmlFor="amount_paid" className="form-label">Valor pago em:</label>
								    <div class="input-group mb-3">
									    <span class="input-group-text" id="addon-wrapping">R$</span>
										<input type="number" min="0" step=".01" class="form-control" onChange={(e) => onChangeMoneyReceived(e)} placeholder="0.00" aria-label="0.00" />
										<button class="btn btn-outline-secondary" type="button" onClick={(e) => resumePurchase()}>Calcular</button>
									</div>
								    <label htmlFor="amount_paid" className="form-label">Troco em: {cashChange.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</label>
								    <span className="error-message">{errors?.amount_paid ? errors.amount_paid[0] : null}</span>
								</Form.Group>
							</Col>
						</Row> */}
						{/* {resumePurchaseId == null ? '' : */}
						    <Row>
						    	<Col sm="12">
									Insira ou aproxime o cartão na maquineta...
						    		{/* <Button type="button" className="btn-sm mb-3" onClick={(e) => finishPurchase()}>PAGAR</Button> */}
						    	</Col>
						    </Row>
					    {/* } */}
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button type="button" onClick={() => setAwaitingPaymentModal(false)} className="btn-sm">Fechar</Button>
                </Modal.Footer>
            </Modal>

		</>
	);
};

export default MethodsPayment;