import React, {useState} from 'react';
import { Link } from "react-router-dom";
import { AiOutlineUser, AiOutlinePlus, AiOutlineShoppingCart } from "react-icons/ai";
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';
import { BiSearchAlt } from "react-icons/bi";
import {useDispatch, useSelector} from 'react-redux';
import {setProducts} from '../../../../store/modules/products/actions.js';
import {changeQuantityProduct, plusQuantityPackages, setCpf} from '../../../../store/modules/shoppingcart/actions.js';
import './style.css';
import apiService from '../../../../services/api.js';
import BarcodeInput from '../../BarcordeInput/index.jsx';
import InputMask from "react-input-mask";

const SearchCustomerByCode = ({showShoppingButton = true}) => {
	const dispatch = useDispatch();
	const [customerSearchValue, setCustomerSearchValue] = useState('');
	const [showCustomerSearch, setShowCustomerSearch] = useState(false);
	const [resetInputEan, setResetInputEan] = useState(false);
	const api = apiService.get();
	const productsShoppingcart = useSelector(state => state.shoppingcart.products);
	let idStore = JSON.parse(localStorage.getItem('toten')).store_id;

	const onChangeCodeEANManual = (event) => {
		setCustomerSearchValue(event.target.value);
	}

	const test = async (value) => {
		console.log("🚀 ~ test ~ value:", value)
		// let value = '1bcd3e13-0831-4107-964c-1b0d1c198688';
		try {
			const resp = await api.get(`api/customer/search/cpf/${value}`);
			setCustomerSearchValue('');
			setShowCustomerSearch(false);
			let cpf = resp.data.data.customers[0]?.cpf || null;
			console.log("🚀 ~ test ~ resp OK:", cpf)
			dispatch(setCpf(cpf));
		} catch (error) {
			console.error("setCpf ~ error:", error)
		}
	}

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			test(customerSearchValue)
			// addProductByEAN(codeEAN);
			// setResetInputEan(true);
		  	// setCodeEAN(''); // Clear the input field after search
		}
	};

	const toggleCustomerSearch = () => {
		setShowCustomerSearch(!showCustomerSearch);
	}

	return (
		<div className="search-customer-by-code">
				
			<button className="btn-code"><AiOutlineUser size="43" onClick={toggleCustomerSearch}/></button>
			<div style={{display: showCustomerSearch ? 'flex' : 'none'}}>
				<InputMask mask="999.999.999-99" name="cpf" className="form-control" id="cpf" onChange={(e) => onChangeCodeEANManual(e)} value={customerSearchValue} onKeyDown={handleKeyPress} />
				<button className="btn-add-product" onClick={() => test(customerSearchValue, true)}><AiOutlinePlus size="25" /></button>
			</div>

		</div>
	);
};

export default SearchCustomerByCode;