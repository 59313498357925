import React, {useState, useEffect, useContext} from 'react';
import { Table, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BiTrash, BiShowAlt, BiXCircle } from "react-icons/bi";
import Header from '../Header/index.jsx';
import apiService from '../../../../services/api.js';
import Loading from '../../../../components/Loading/index.jsx';
import Pagination from '../../../../components/Pagination/index.jsx';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';
import Context from "../../../../Context";

const List = ({ match, location }) => {
	const [configDefault] = useContext(Context);
	// eslint-disable-next-line
	const [endpoint, setEndpoint] = useState('purchase');
	// eslint-disable-next-line
    const [roleEndpoint, setRoleEndpoint] = useState('purchase');
	const [purchases, setPurchases] = useState([]);
	const [loading, setLoading] = useState(false);
	const [offset, setOffset] = useState(1);
	const [qtdPages, setQtdPages] = useState(1);
	const api = apiService.get()
	let valuePrev = localStorage.getItem('searchValuePrev');
	const {
	    params: { column, value }
	} = match;

	const list = () => {
		setLoading(true);
		let queryApi = '';
		if(typeof column !== "undefined" && typeof value !== "undefined"){
			if(valuePrev !== value){
				setOffset(1)
				localStorage.setItem('searchValuePrev', value);
			}
			queryApi = `api/${endpoint}/search/${column}/${value}?page=${offset}`;
		}else{
			queryApi = `api/${endpoint}?page=${offset}`;
		}
		
		api.get(queryApi)
		.then(resp => {
			setPurchases(resp.data.data.purchases);
			setQtdPages(resp.data.data.pagination.pages);
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoading(false);
			verifyError(error);
		})
	}
	
	const deleteItem = (id, e) => {
		setLoading(true);
		api.delete(`api/${endpoint}/${id}`)
		.then(resp => {
			showNotify('success', 'Compra deletada com sucesso!');
			list();
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoading(false);
			verifyError(error);
		})
	}

	const cancelItem = (id, e) => {
		setLoading(true);
		api.post(`api/${endpoint}/cancel-payment/${id}`)
		.then(resp => {
			showNotify('success', 'Pagamento cancelado com sucesso!');
			list();
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoading(false);
			verifyError(error);
		})
	}

	useEffect(() => {
		list()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [column, value, offset])


	return (
		<>
			<div className="area-crud">

				<Header></Header>

				<Can 
					resource={roleEndpoint}
					authority={`${roleEndpoint}-view`}
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Lista de compras</h5>

					{ (loading) ? (<Loading />) : (

						(purchases?.length === 0) ? (
		                    <label>Nenhum registro encontrado...</label>
		                ) : (
		                	<div>
			                    <Table striped bordered hover>
									<thead>
										<tr>
											<th style={configDefault?.theme?.prymaryBackground}>Id</th>
											<th style={configDefault?.theme?.prymaryBackground}>Quantidade</th>
											<th style={configDefault?.theme?.prymaryBackground}>Total</th>
											<th style={configDefault?.theme?.prymaryBackground}>Satus</th>
											<th style={configDefault?.theme?.prymaryBackground}>Ações</th>
										</tr>
									</thead>
									<tbody>
										{purchases?.map((item) => (
											<tr key={item.id}>
												<td>{item.id}</td>
												<td>{item.quantity_items}</td>
												<td>{(item.total / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
												<td>{item.status}</td>
												<td>
													<Can resource={roleEndpoint} authority={`${roleEndpoint}-view`}>
														<Link to={`/dashboard/${endpoint}/view/${item.id}`} className="btn btn-dark" style={configDefault?.theme?.prymaryBackground}>
															<BiShowAlt />
														</Link>
													</Can>

													<Can resource={roleEndpoint} authority={`${roleEndpoint}-delete`}>
														<Button variant="dark" style={configDefault?.theme?.prymaryBackground} onClick={(e) => {if (window.confirm('Você realmente deseja cancelar este registro?')) cancelItem(item.id, e)}}>
															<BiXCircle />
														</Button>
													</Can>
													<Can resource={roleEndpoint} authority={`${roleEndpoint}-delete`}>
														<Button variant="dark" style={configDefault?.theme?.prymaryBackground} onClick={(e) => {if (window.confirm('Você realmente deseja excluir este registro?')) deleteItem(item.id, e)}}>
															<BiTrash />
														</Button>
													</Can>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
								<Pagination
									qtdPages={qtdPages}
									offset={offset}
									setOffset={setOffset}
								/>

							</div>
		                )
		            )}
	            </Can>
	            
			</div>
		</>
	);
};

export default List;