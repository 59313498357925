import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Button, Form, Row, Col } from 'react-bootstrap';
import './style.css';
import apiService from '../../../services/api.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {

	const api = apiService.get();
	const [stores, setStores] = useState([]);
	const [totens, setTotens] = useState([]);
	const [errors, setErrors] = useState('');
	const { register, handleSubmit } = useForm();
	const toastId = React.useRef(null);

	const notify = () => {
		toastId.current = toast("Aguarde...", {
		  type: toast.TYPE.INFO,
	      autoClose: false,
	      closeButton: false // Remove the closeButton
	    });
	}

	const showNotifyError = (message) => {
		toast.update(toastId.current, {
		  render: message,
	      type: toast.TYPE.ERROR,
	      autoClose: 3000,
	      closeButton: null,
	    });
	}

	const initStores = () => {
		api.get('api/store/all')
		.then(resp => {
			const data = resp.data.data.stores;
			setStores(data)
		})
		.catch(error =>{
			setErrors(error.message);
		})
	}

	const onChangeStore = (e) => {
		let storeId = e.target.value;
		api.get(`api/toten/by-store/${storeId}`)
		.then((resp) => {
			const data = resp.data.data.totens;
			setTotens(data)
		})
		.catch(error =>{
			setErrors(error.message);
		})
	}


	const login = data => {
		notify();
		api.defaults.withCredentials = true;
		api.get("/sanctum/csrf-cookie").then((response) => {
			api.post('api/auth/login-toten', data)
			.then(resp => {
				console.log(resp.data.data);
				//let dataStore = resp.data.data.store;
				let dataToten = resp.data.data.toten;
				//let dataUserToten = resp.data.data.store;
				let tokenUserToten = resp.data.data.token;
				localStorage.setItem('toten', JSON.stringify(dataToten));
				localStorage.setItem('tokenUserToten', JSON.stringify(tokenUserToten));
				
				window.location = '/panel-toten/';
			})
			.catch(error =>{
				if(error?.response?.data?.data?.errors){
					showNotifyError('Alguns dados estão incorretos!');
					setErrors(error?.response?.data?.data?.errors);
				}

				if(error?.response?.data?.message !== null)
					showNotifyError(error?.response?.data?.message);
			});
		});
	}

	useEffect(() => {
		initStores();
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="login-auth">
				<div className="container mx-auto w-50 mb-3">
					<Form onSubmit={handleSubmit(login)} className="form-register">
						<h1 className="mx-auto text-center">Acesso ao PDV</h1>
						<Row className="mb-3">
							<Col sm="12">
								<Form.Group>
								    <label htmlFor="email" className="form-label">Email:</label>
								    <input type="email" name="email" className="form-control" id="email" {...register("email")} />
								    <span className="error-message">
								    	{errors?.email ? errors.email[0] : null}
								    </span>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="12">
								<Form.Group>
								    <label htmlFor="password" className="form-label">Senha:</label>
								    <input type="password" name="password" className="form-control" id="password" {...register("password")} />
								    <span className="error-message">
								    	{errors?.password ? errors.password[0] : null}
								    </span>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="12">
								<Form.Group>
								    <label htmlFor="store" className="form-label">Loja:</label>
								    <select className="form-select" name="store_id" {...register("store_id")} onChange={(e) => onChangeStore(e)}>
								    	<option value="">Selecionar</option>
							            {stores?.map((store, index) => (
								    		<option value={store.id} key={index}>{store.name}</option>
								    	))}
								    </select>

								    <span className="error-message">
								    	{errors?.store ? errors.store[0] : null}
								    </span>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="12">
								<Form.Group>
								    <label htmlFor="toten" className="form-label">PDV:</label>
								    <select className="form-select" {...register("toten_id")}>
								    	<option value="">Selecionar</option>
							            {totens?.map((toten, index) => (
								    		<option value={toten.id} key={index}>{toten.name}</option>
								    	))}
								    </select>

								    <span className="error-message">
								    	{errors?.toten ? errors.toten[0] : null}
								    </span>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="12">
								<Button type="submit" className="btn btn-primary w-100">Entrar</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</div>
		</>
	);
};

export default Login;